<template id="tmplCustomerInformation">
  <div class="mb-3" id="divCustomerInformation">
    <b-card title="Customer Information" id="crdCustomerInformation">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-row v-if="customer" id="rowCustomerInformation">
        <b-col cols="12" id="colCustomerId">
          <p id="pCustomerId"><b id="bCustomerId">Id: </b>{{ customer.id }}</p>
        </b-col>
        <b-col lg="6" md="6" sm="12" id="colCustomerName">
          <b-form-group label="Customer Name" label-for="txtCustomerName" id="frmCustomerName">
            <b-form-input
              id="txtCustomerName"
              v-model="customer.name"
              :class="validateState('name') === false ? 'is-invalid' : ''"
              aria-describedby="txtCustomerName-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="txtCustomerName-live-feedback"
              >Customer name is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <div v-if="customerUrlTextbox === 'true'" id="divCustomerUrlTextBox">
            <label id="lblCustomerUrlTextBox">White-label Sub Domain</label>
            <b-input-group id="grpCustomerUrlTextBox">
              <b-form-input
                id="txtCompanyName"
                v-model="customer.subDomain"
                :class="validateState('subDomain') === false ? 'is-invalid' : ''"
                v-b-popover.hover.html="tooltips.whiteLabelSubDomainText"
                aria-describedby="txtCompanyName-live-feedback"
              ></b-form-input>
              <b-input-group-text id="iptGrpTextBox">.tripfocus.com</b-input-group-text>
              <b-form-invalid-feedback id="txtCompanyName-live-feedback"
                >White-label Sub Domain accepts only letters and
                numbers.</b-form-invalid-feedback
              > </b-input-group
            ><br id="brCompanyName" />
          </div>
        </b-col>

        <b-col cols="12" v-if="canEdit" id="colSaveCustomerInformation">
          <b-button
            btn="btnSaveCustomerInformation"
            id="btnSaveCustomerInformation"
            variant="primary"
            class="btn-block-xs-only"
            @click="saveCustomer()"
            >Save Customer Information</b-button
          >
          <b-button
            btn="btnResetCustomerInformation"
            id="btnResetCustomerInformation"
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="loadCustomer()"
            >Reset Customer Information</b-button
          >
        </b-col>
        <b-col cols="12" v-else id="colAltCustomerInformation">
          <div class="alert alert-warning" role="alert" id="dAltCustomerInformation">
            KAG Internal Customer Information can not be edited.
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactory } from "@/services/RepositoryFactory";
const CustomersRepository = RepositoryFactory.get("customersRepository");

import { KagInternalCustomerId } from "@/shared/utils/permissions";

import { required, alphaNum } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "@/shared/utils/response-error-handler";

import { customerUrlTextbox } from "@/shared/feature-flags";
import { tooltips } from "@/shared/tooltip";

export default {
  name:"CustomerInformation.vue",
  props: ["customerId"],
  components: {
    Loading
  },
  data() {
    return {
      customer: null,
      isLoading: false,
      inputValidations: inputValidations,
      customerUrlTextbox,
      tooltips
    };
  },
  async mounted() {
    await this.loadCustomer();
  },
  methods: {
    ...mapActions("customers", ["setSelectedCustomer"]),
    async loadCustomer() {
      this.isLoading = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        customerId: this.customerId
      };

      try {
        let response = await CustomersRepository.getById(requestData);

        this.customer = response.data;
        this.setSelectedCustomer(this.customer);
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
    },
    async saveCustomer() {
      try {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.isLoading = true;

          var requestData = {
            jwtToken: this.$store.state.user.jwtToken,
            id: this.customer.id,
            customerData: this.customer
          };

          await CustomersRepository.update(requestData);

          this.isLoading = false;

          this.$snotify.success(
            `Customer Information updated successfully.`,
            "Success",
            {
              timeout: 1500,
              showProgressBar: true
            }
          );
        }
      } catch (error) {
        console.log(error);

        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.customer[name];

      return $dirty ? !$error : null;
    }
  },
  computed: {
    ...mapGetters("customers", ["getSelectedCustomer"]),
    KagInternalCustomerId() {
      return KagInternalCustomerId;
    },
    canEdit() {
      return (
        this.customerId.toUpperCase() !== KagInternalCustomerId.toUpperCase()
      );
    }
  },
  validations: {
    customer: {
      name: {
        required
      },
      subDomain: {
        alphaNum
      }
    }
  }
};

</script>
