<template id="tmplNewCustomerInformation">
  <div class="mb-3" id="divNewCustomerInformation">
    <b-card title="Customer Information" id="crdNewCustomerInformation">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-row id="rowNewCustomerInformation">
        <b-col lg="6" md="6" sm="12" id="colNewCustomerInformation">
          <b-form-group label="Customer Name" label-for="txtCustomerName" id="frmCustomerName">
            <b-form-input
              id="txtCustomerName"
              v-model="customer.name"
              :class="validateState('name') === false ? 'is-invalid' : ''"
              aria-describedby="txtCustomerName-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="txtCustomerName-live-feedback"
              >Customer name is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <div v-if="customerUrlTextbox === 'true'" id="divSubDomain">
            <label id="lblSubDomain">White-label Sub Domain</label>
            <b-input-group id="grpSubDomain">
              <b-form-input
                id="txtCompanyName"
                v-model="customer.subDomain"
                :class="validateState('subDomain') === false ? 'is-invalid' : ''"
                v-b-popover.hover.html="tooltips.whiteLabelSubDomainText"
                aria-describedby="txtCompanyName-live-feedback"
              ></b-form-input>
              <b-input-group-text id="grpDomain">.tripfocus.com</b-input-group-text>
              <b-form-invalid-feedback id="txtCompanyName-live-feedback"
                >White-label Sub Domain accepts only letters and
                numbers.</b-form-invalid-feedback
              >
            </b-input-group>
            <br id="brDomain" />
          </div>
        </b-col>

        <b-col cols="12" id="colSaveCustomerInformation">
          <b-button
            id="btnSaveCustomerInformation"
            variant="primary"
            class=" btn-block-xs-only"
            @click="saveCustomer()"
            >Create Customer</b-button
          >

          <b-button
            id="btnCancelCustomerInformation"
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="cancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactory } from "@/services/RepositoryFactory";
const CustomerRepository = RepositoryFactory.get("customersRepository");

import { validationMixin } from "vuelidate";

import { required, alphaNum } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";

import { handleRequestError } from "@/shared/utils/response-error-handler";
import MaskedInput from "vue-text-mask";
import CustomersMultiSelect from "@/components/customers/CustomersMultiSelect";

import { customerUrlTextbox } from "@/shared/feature-flags";
import { tooltips } from "@/shared/tooltip";

export default {
  name:"NewCustomerInformation.vue",
  mixins: [validationMixin],
  props: ["customerId"],
  components: {
    MaskedInput,
    Loading,
    CustomersMultiSelect
  },
  data() {
    return {
      customer: {
        name: "",
        subDomain: "",
        isTripFocus: true,
        activeTripFocus: true
      },
      isLoading: false,
      inputValidations: inputValidations,
      customerUrlTextbox,
      tooltips
    };
  },
  async mounted() {},
  methods: {
    async saveCustomer() {
      try {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.isLoading = true;

          console.log(this.customer);

          const requestData = {
            jwtToken: this.$store.state.user.jwtToken,
            customerData: this.customer
          };

          const response = await CustomerRepository.create(requestData);

          this.isLoading = false;

          this.$snotify.success(`Customer created successfully.`, "Success", {
            timeout: 1500,
            showProgressBar: true
          });

          this.$router.push({
            name: "admin-edit-customer",
            params: { id: response.data.id }
          });
        }
      } catch (error) {
        console.log(error);

        const errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$router.push({
        name: "internal-admin-customer"
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.customer[name];

      return $dirty ? !$error : null;
    }
  },
  computed: {},
  validations: {
    customer: {
      name: {
        required
      },
      subDomain: {
        alphaNum
      }
    }
  }
};
</script>
<style scoped>
div.is-invalid div{
  border-color: #c22032;
}

div.is-invalid ~ {
  display: block;
}
</style>
