<template id ="tmplCustomerView">
  <div class="container vld-parent" id ="divCustomerViewContainer">
    <b-row id="rowCustomerView">
      <b-card class="w-100" id="crdNewEditCustomer">
        <b-row id="rowNewEditCustomer">
          <b-col class="mb-2" id="colNewEditCustomer">
            <b-card-title class="d-inline" id="ttlNewEditCustomer">{{
              customerId ? "Edit Customer" : "Create New Customer"
            }}</b-card-title>

            <b-button
              id="btnBackToAllCustomers"
              @click="goBackToUsers"
              variant="outline-primary"
              size="sm"
              class="float-right btn-block-xs-only  mt-2 mt-md-0"
              >&lt; Back To All Customers</b-button
            ></b-col
          >
        </b-row>

        <tf-customer-information
           id="tfCustInfCustId"
          :customerId="customerId"
          v-if="customerId"
        ></tf-customer-information>
        <tf-new-customer-information id="tfNewCustInfCustID" v-else></tf-new-customer-information>

				<tf-customer-reference-number-restrictions
					id="tfCustRefNumRest"
					:customerId="customerId"
          v-if="customerId"
				></tf-customer-reference-number-restrictions>

        <tf-customer-permissions
          id="tfCustPrmCustId"
          :customerId="customerId"
          v-if="customerId"
        ></tf-customer-permissions>

        <tf-customer-data-restrictions
          id="tfCustdtaRstCustId"
          :loggedInUserId="loggedInUserId"
          :selectedCustomer="getSelectedCustomer"
          v-if="customerId && getSelectedCustomer"
        ></tf-customer-data-restrictions>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TfCustomerInformation from "@/components/customers/CustomerInformation";
import TfCustomerReferenceNumberRestrictions from "@/components/customers/CustomerReferenceNumberRestrictions";
import TfNewCustomerInformation from "@/components/customers/NewCustomerInformation";
import TfCustomerDataRestrictions from "@/components/customers/CustomerDataRestrictions";
import TfCustomerPermissions from "@/components/customers/CustomerPermissions";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { handleRequestErrorNew } from "@/shared/utils/response-error-handler";

const DocTypesRepository = RepositoryFactory.get("docTypesRepository");
const MasterPermissionsRepository = RepositoryFactory.get(
  "masterPermissionsRepository"
);

export default {
  name:"Customer.vue",
  props: [],
  components: {
    TfCustomerInformation,
    TfNewCustomerInformation,
    TfCustomerDataRestrictions,
    TfCustomerPermissions,
		TfCustomerReferenceNumberRestrictions
  },
  data() {
    return {};
  },
  async created() {},
  async mounted() {
    if (
      !this.getGlobalCustomerDocTypes ||
      this.getGlobalCustomerDocTypes.length === 0
    ) {
      this.loadGlobalDocTypes();
    }
    //if (!this.getGlobalMasterPermissions || this.getGlobalMasterPermissions.length == 0) {
    this.loadMasterPermissions();
    //}
  },
  methods: {
    ...mapActions("customers", [
      "setSelectedCustomer",
      "setGlobalCustomerDocTypes",
      "setGlobalMasterPermissions"
    ]),
    goBackToUsers() {
      this.$router.push({
        name: "internal-admin-customer"
      });
    },
    async loadGlobalDocTypes() {
      try {
        let response = await DocTypesRepository.get();
        this.setGlobalCustomerDocTypes(response.data);
      } catch (error) {
        const errorMessage = handleRequestErrorNew(error);
        this.$snotify.error(errorMessage.title, "Error");
      }
    },
    async loadMasterPermissions() {
      const requestData = {
        customerId: this.customerId
      };

      try {
        let response = await MasterPermissionsRepository.get(requestData);
        this.setGlobalMasterPermissions(response.data);
      } catch (error) {
        const errorMessage = handleRequestErrorNew(error);
        this.$snotify.error(errorMessage.title, "Error");
      }
    }
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("customers", [
      "getSelectedCustomer",
      "getGlobalCustomerDocTypes",
      "getGlobalMasterPermissions"
    ]),
    customerId() {
      return this.$route.params.id;
    },
    loggedInUserId() {
      return this.oidcUser.sub;
    }
  },
  destroyed() {
    //do this so it's not still there when they come back to the page while the next one loads.
    this.setSelectedCustomer(null);
  }
};
</script>

<style scoped></style>
