<template id="tmplCustRefNumRestrictions">
	<div class="mb-3">
		<b-card title="Customer Reference Number Restrictions">
			<loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>
			<!-- Select row -->
			<b-row>
				<b-col>
					<b-form-group
            label="Reference Number Permissions"
            label-for="custRefNumbTreeSelect"
          >
						<treeselect
							instanceId="custRefNumbTreeSelect"
							v-model="customerAssignedOptions"
							:multiple="true"
							:options="customerAvailableOptions"
							valueFormat="object"
						>
							<div 
								slot="value-label" 
								slot-scope="{ node }"
							>
							{{ node.label }} - {{ node.raw.division }}
							</div>
							<label
								id="lblClassNamePermissions"
								slot="option-label"
								slot-scope="{
									node
								}"
							>
                {{ node.label }} - {{ node.raw.division }}
              </label>
						</treeselect>
					</b-form-group>
				</b-col>
			</b-row>
			<!-- Buttons row -->
			<b-row>
				<b-col>
          <b-button
            id="btnSaveCustRefNum"
            variant="primary"
            class=" btn-block-xs-only"
            @click="saveCustRefNums()"
            >Save Customer Reference Numbers</b-button
          >
          <b-button
            id="btnResetCustRefNum"
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="resetAssignedCustRefNums()"
            >Reset Customer Reference Numbers</b-button
          >
        </b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { mapGetters } from 'vuex';
const CustomerRepository = RepositoryFactory.get("customersRepository");

export default {
	name:"CustomerReferenceNumberRestrictions.vue",
	props: ["customerId"],
	components: {
		Treeselect,
    Loading
  },
	data() {
    return {
      isLoading: false,
			customerAssignedOptions: [],
			customerAvailableOptions: []
    };
  },
	async created(){
		await this.loadCustomerReferenceNumberRestrictions();
	},
	computed: {
    ...mapGetters('customers', [
      'getSelectedCustomer'
    ])
  },
	methods:{
		async loadCustomerReferenceNumberRestrictions(){
			this.isLoading = true;
			await this.loadAvailableCustRefNums();
			await this.loadAssignedCustRefNums();
			this.isLoading = false;
		},

		async resetAssignedCustRefNums(){
			this.isLoading = true;
			await this.loadAssignedCustRefNums();
			this.isLoading = false;
		},

		//Loads available for dropdown
		async loadAvailableCustRefNums(){
			const availableCustRefNumPermissions = await CustomerRepository.getAvailableCustRefNumbPermissions(this.customerId);
			if(availableCustRefNumPermissions){
				this.customerAvailableOptions = availableCustRefNumPermissions.map(function(custRefNum){
					return {
						id: `${custRefNum.abbr}-${custRefNum.division}`,
						abbr: custRefNum.abbr,
						label: custRefNum.name,
						division: custRefNum.division
					}
				});
			}
		},

		//Loads previously assigned for customer
		async loadAssignedCustRefNums(){
			const assignedCustRefNumPermissions = await CustomerRepository.getAssignedCustRefNumbPermissions(this.customerId);
			if(assignedCustRefNumPermissions){
				this.customerAssignedOptions = assignedCustRefNumPermissions.map(function(assgnRefNum){
					return {
						id: `${assgnRefNum.abbr}-${assgnRefNum.division}`,
						abbr: assgnRefNum.abbr,
						label: assgnRefNum.name,
						division: assgnRefNum.division
					}
				});
			}
		},

		async saveCustRefNums(){
			this.isLoading = true;
			const requestData = {
				customerId: this.customerId,
				referenceNumberTypes: this.customerAssignedOptions.map(function(assignedCustRefNum){
					return{
						name: assignedCustRefNum.label,
						abbr: assignedCustRefNum.abbr,
						division: assignedCustRefNum.division
					}
				})
			}

			const response = await CustomerRepository.assignOrUpdateCustRefNumbPermissions(requestData);
			if(response.success){
				this.$snotify.success("Reference number types assigned successfully.", "Success", {
					timeout: 1500,
					showProgressBar: true
				})
				await this.loadAssignedCustRefNums();
			}else{
				this.$snotify.error(response.data, "Error")
			}
			this.isLoading = false;
		}
	}
}
</script>
